import styled from 'styled-components'
import { ExclamationTriangleIcon } from './Icons'

const WarningDisclaimerContainer = styled.label`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px;
  margin: 8px;
  border-radius: 4px;
  display: flex;
  color: ${({ theme }) => theme.colors.font.primary};
  background-color: ${({ theme }) => theme.colors.disclaimerBackground};
  width: inherit;
`

type WarningDisclaimerProps = {
  message: string
}

export const WarningDisclaimer = (props: WarningDisclaimerProps) => (
  <WarningDisclaimerContainer>
    <ExclamationTriangleIcon style={{ minWidth: '2em', minHeight: '2em' }} />
    <div>{props.message}</div>
  </WarningDisclaimerContainer>
)
