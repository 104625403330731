import { createContext } from 'react'
import { TeamBySlugQuery, WhoamiQuery } from 'sdk'

export type ActiveTeam = TeamBySlugQuery['teams']['edges'][0]['node']
export type CurrentUser = NonNullable<WhoamiQuery['whoami']>

interface UserContextInterface {
  currentUser: CurrentUser
  activeTeam: ActiveTeam | null
  userIsActiveTeamAdmin: boolean | null
}

const defaultUserValue = {
  currentUser: null as unknown as CurrentUser,
  activeTeam: null,
  userIsActiveTeamAdmin: null,
}

const defaultTeamValue = {
  activeTeam: undefined as unknown as ActiveTeam,
  userIsActiveTeamAdmin: undefined as unknown as boolean,
}

interface TeamContextInterface extends UserContextInterface {
  activeTeam: ActiveTeam
  userIsActiveTeamAdmin: boolean
}

export const UserContext = createContext<UserContextInterface>({
  ...defaultUserValue,
  activeTeam: null,
})

export const TeamContext = createContext<TeamContextInterface>({
  ...defaultTeamValue,
  currentUser: defaultUserValue.currentUser,
})

interface NavigationContextProviderProps {
  currentUser: NonNullable<WhoamiQuery>
  activeTeam?: ActiveTeam
  children: JSX.Element
}

export const NavigationContextProvider = ({
  activeTeam,
  currentUser,
  children,
}: NavigationContextProviderProps) => {
  // We always have user context, but team context depends on navigation
  // Even though TeamContext is superset of UserContext, we provide UserContext
  // for convenience, e.g. for TopNav which needs to be rendered always.
  if (activeTeam) {
    // Determine if user is an admin of the active team
    const userIsActiveTeamAdmin =
      currentUser.whoami.teams.findIndex(
        (team) => team.role === 'admin' && team.id === activeTeam.id
      ) > -1

    const contextPayload = {
      currentUser: currentUser.whoami,
      activeTeam,
      userIsActiveTeamAdmin,
    }
    return (
      <UserContext.Provider value={contextPayload}>
        <TeamContext.Provider value={contextPayload}>
          {children}
        </TeamContext.Provider>
      </UserContext.Provider>
    )
  } else {
    return (
      <UserContext.Provider
        value={{
          currentUser: currentUser.whoami,
          activeTeam: null,
          userIsActiveTeamAdmin: null,
        }}
      >
        {children}
      </UserContext.Provider>
    )
  }
}
