import styled from 'styled-components'
import { Logo } from './Logo'
import { useEffect, useState } from 'react'

const AppLoadingSplashContainer = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  opacity: 1;

  /* Use fade-in to avoid flicker for fast render */
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  font-size: 32px;
`

const AnimatedIcon = () => {
  const clouds = ['☁️', '🌧', '🌩', '🌨', '🌦', '🌥', '🌤', '☀️']
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const animation = setInterval(() => {
      setIndex((index) => (index + 1) % clouds.length)
    }, 200)
    return () => clearInterval(animation)
  })

  return <span>{clouds[index]}</span>
}

export function AppLoadingSplash(): JSX.Element {
  return (
    <AppLoadingSplashContainer>
      <Wrapper>
        <Logo />
        <AnimatedIcon />
      </Wrapper>
    </AppLoadingSplashContainer>
  )
}
