import { useState } from 'react'
import styled from 'styled-components'
import { Button, ButtonDanger } from './Button'
import { FormTextInput } from './FormTextInput'
import { WarningDisclaimer } from './WarningDisclaimer'
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline'

const KeyValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const KeyValueRowStyles = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
`

const KeyValueButton = styled(Button)`
  width: 200px;
  height: 42px;
  align-self: flex-end;
`

interface KeyValueRowCommonProps {
  onChange: (keyName: string, value: string) => void
  keyName: string
  value: string
}

interface KeyValueRowAddProps extends KeyValueRowCommonProps {
  type: 'add'
  onAdd: (keyName: string, value: string) => void
  disableButton: boolean
}

interface KeyValueRowEditProps extends KeyValueRowCommonProps {
  type: 'edit'
  onRemove: (keyName: string) => void
}

const KeyValueRow = (
  props: KeyValueRowAddProps | KeyValueRowEditProps
): JSX.Element => {
  return (
    <KeyValueRowStyles>
      <FormTextInput
        label={'Key'}
        type="text"
        name="keyName"
        value={props.keyName}
        onChange={(event) =>
          props.onChange(event.currentTarget.value, props.value)
        }
        disabled={props.type === 'edit'}
        required
      />
      <FormTextInput
        label={'Value'}
        type="text"
        id="value"
        name="value"
        value={props.value}
        onChange={(event) =>
          props.onChange(props.keyName, event.currentTarget.value)
        }
      />
      {props.type === 'edit' ? (
        <KeyValueButton
          onClick={(event) => {
            event.preventDefault()
            props.onRemove(props.keyName)
          }}
        >
          Remove
        </KeyValueButton>
      ) : (
        <KeyValueButton
          onClick={() => props.onAdd(props.keyName, props.value)}
          disabled={props.disableButton}
        >
          Add
        </KeyValueButton>
      )}
    </KeyValueRowStyles>
  )
}

export function KeyValueInput({
  originalRecord,
  record,
  onChange,
}: {
  originalRecord: Readonly<Record<string, string>>
  record: Record<string, string>
  onChange: (record: Record<string, string>) => void
}): JSX.Element {
  const isChanged = JSON.stringify(originalRecord) !== JSON.stringify(record)
  const entries = Object.entries(record)
  const [newKeyValue, setNewKeyValue] = useState(['', ''])
  const isDuplicateKey = newKeyValue[0] in record
  return (
    <KeyValueContainer>
      {entries.length > 0 ? (
        entries.map(([keyName, value]) => {
          return (
            <KeyValueRow
              type={'edit'}
              key={keyName}
              onChange={(k, v) => {
                onChange({ ...record, [k]: v })
              }}
              onRemove={(k) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [k]: _, ...rest } = record
                onChange(rest)
              }}
              keyName={keyName}
              value={value}
            />
          )
        })
      ) : (
        <p>No key value pairs</p>
      )}
      {isChanged && (
        <>
          <WarningDisclaimer message={'You have unsaved changes'} />
          <ButtonDanger
            type={'button'}
            disabled={!isChanged}
            onClick={() => {
              onChange({ ...originalRecord })
            }}
          >
            Reset changes
            <ArrowUturnLeftIcon style={{ height: '16px', marginLeft: '8px' }} />
          </ButtonDanger>
        </>
      )}
      <KeyValueRow
        type={'add'}
        keyName={newKeyValue[0]}
        value={newKeyValue[1]}
        onChange={(k, v) => {
          setNewKeyValue([k, v])
        }}
        onAdd={(k, v) => {
          onChange({ ...record, [k]: v })
          setNewKeyValue(['', ''])
        }}
        disableButton={newKeyValue[0].length === 0 || isDuplicateKey}
      />
      {isDuplicateKey && (
        <WarningDisclaimer
          message={'Key already exists, edit existing value or use a new key'}
        />
      )}
    </KeyValueContainer>
  )
}
