import styled from 'styled-components'

interface DropdownStylesProps {
  direction: 'left' | 'right' | undefined
  $zIndex?: string
}

const DropdownStyles = styled.div<DropdownStylesProps>`
  top: 36px;
  transform: ${(props) =>
    props.direction === 'left'
      ? 'translate(-90%, 0px);'
      : 'translate(0%, 0px);'};
  background-color: ${(props) => props.theme.colors.background};

  position: absolute;
  min-width: 225px;

  border-radius: var(--border-radius);
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.border};

  z-index: ${(props) => props.$zIndex || 'initial'};
`

export function Dropdown({
  children,
  direction,
  zIndex,
}: {
  children: JSX.Element
  direction?: 'left' | 'right' | undefined
  zIndex?: string
}): JSX.Element {
  return (
    <DropdownStyles $zIndex={zIndex} direction={direction}>
      {children}
    </DropdownStyles>
  )
}

export const DropdownButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  border: none;
  padding-left: 2px;
  padding-right: 2px;
  margin-left: 2px;
  margin-right: 2px;
  color: ${({ theme }) => theme.colors.foreground};
  background: ${({ theme }) => theme.colors.background};

  &:hover,
  &:focus,
  &:active {
    border-radius: var(--border-radius);
    background: ${({ theme }) => theme.colors.hoverBackground};
  }
`
