import { TextareaHTMLAttributes } from 'react'
import styled from 'styled-components'

export const TextAreaContainer = styled.div`
  align-items: center;
  cursor: default;
  display: flex;
  max-width: 100%;
  width: 100%;
  color: ${({ theme }) => theme.colors.foreground};
`

export const TextAreaHandler = styled.textarea`
  appearance: none;
  font: inherit;
  font-size: 1rem;
  border-color: ${({ theme }) => theme.colors.border};
  border-radius: var(--border-radius);
  border-style: solid;
  border-width: 1px;
  line-height: normal;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  min-height: 200px;
  min-width: 0;
  order: 1;
  padding-bottom: 0;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  outline: none;
  width: 100%;

  &:focus {
    border-color: ${({ theme }) => theme.colors.foreground};
  }
`

export const TextArea = (
  props: TextareaHTMLAttributes<HTMLTextAreaElement>
) => {
  return (
    <TextAreaContainer>
      <TextAreaHandler {...props} />
    </TextAreaContainer>
  )
}
