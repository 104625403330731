import TimeAgo from 'timeago-react'
import * as timeago from 'timeago.js'

import en_short from 'timeago.js/lib/lang/en_short'

const shortLocale = 'en_short'
timeago.register(shortLocale, en_short)

import { Hint } from './Hint'

interface RelativeTimeProps {
  time: Date | string
  getFullTimestamp?: boolean
}

export function RelativeTime({
  time,
  getFullTimestamp = true,
}: RelativeTimeProps): JSX.Element {
  const timeobject = typeof time === 'string' ? new Date(time) : time
  return (
    <>
      <TimeAgo datetime={timeobject} locale={shortLocale} live={false} />{' '}
      {getFullTimestamp && <Hint message={timeobject.toString()} />}
    </>
  )
}
