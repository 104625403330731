import {
  CheckIcon,
  PlusCircleIcon,
  BiggerSelectorIcon as SelectorIcon,
} from './Icons'
import { useRouter } from 'next/router'
import { useState } from 'react'
import styled from 'styled-components'
import { useOuterClick } from 'utils'
import {
  Divider,
  Dropdown,
  DropdownButton,
  Menu,
  MenuButton,
  MenuText,
  UserContext,
} from '.'

import { useContext } from 'react'
import { TeamDropdownQuery, useTeamDropdownQuery } from 'sdk'

const ActiveAndSwitcher = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
`
const ActiveText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  wrap-text: nowrap;
  text-overflow: ellipsis;
`

interface TeamListProps {
  teams: TeamDropdownQuery['teams']['edges'][number]['node'][]
  activeTeamId?: string
  onClick: (teamSlug: string) => void
}

const TeamList = ({ activeTeamId, teams, onClick }: TeamListProps) => {
  return (
    <>
      {teams.map((team) => (
        <MenuButton
          $active={activeTeamId === team.id}
          key={team.id}
          onClick={() => {
            onClick(team.slug)
          }}
        >
          {team.name}
          {activeTeamId === team.id && <CheckIcon />}
        </MenuButton>
      ))}
      <Divider />
    </>
  )
}

export function TeamSwitcher(): JSX.Element | null {
  const router = useRouter()
  const { activeTeam, currentUser } = useContext(UserContext)

  const [open, setOpen] = useState(false)
  const innerRef = useOuterClick(() => {
    setOpen(false)
  })

  const teamDropdownQuery = useTeamDropdownQuery()

  return (
    <div ref={innerRef}>
      <ActiveAndSwitcher>
        <ActiveText>
          {activeTeam
            ? activeTeam.name
            : `${currentUser.firstName} ${currentUser.lastName}`}
        </ActiveText>
        <DropdownButton onClick={() => setOpen(!open)}>
          <SelectorIcon />
        </DropdownButton>
      </ActiveAndSwitcher>

      {open && (
        <Dropdown direction={'right'} zIndex={'10'}>
          <Menu>
            <MenuText>Personal Account</MenuText>
            <MenuButton
              $active={!activeTeam}
              onClick={() => {
                setOpen(false)
                router.push('/user/dashboard')
              }}
            >
              {currentUser.firstName} {currentUser.lastName}
              {!activeTeam && <CheckIcon />}
            </MenuButton>
            <MenuText>Teams</MenuText>
            {teamDropdownQuery.isLoading ? (
              <MenuButton>Loading...</MenuButton>
            ) : !teamDropdownQuery.isSuccess ? (
              <MenuButton>
                Failed to load teams, please reload to try again
              </MenuButton>
            ) : (
              <TeamList
                activeTeamId={activeTeam?.id}
                teams={teamDropdownQuery.data.teams.edges.map(
                  (edge) => edge.node
                )}
                onClick={(teamSlug) => {
                  setOpen(false)
                  router.push(`/teams/${teamSlug}`)
                }}
              />
            )}
            <MenuButton
              onClick={() => {
                setOpen(false)
                router.push('/create-team/')
              }}
            >
              <PlusCircleIcon />
              Create New Team
            </MenuButton>
          </Menu>
        </Dropdown>
      )}
    </div>
  )
}
