import { useRouter } from 'next/router'
import styled from 'styled-components'
import { Logo, TeamSwitcher, TopNavDropdown } from '.'

const TopNavStyles = styled.nav`
  grid-area: topnav;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  gap: 16px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;

  background-color: ${({ theme }) => theme.colors.navbarBackground};

  max-width: 100%;
`

const LeftNavStyles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`

export function TopNav(): JSX.Element {
  const router = useRouter()
  const { teamSlug } = router.query

  const defaultRoute = teamSlug ? `/teams/${teamSlug}` : '/'

  return (
    <TopNavStyles>
      <LeftNavStyles>
        <Logo href={defaultRoute} />
        <TeamSwitcher />
      </LeftNavStyles>
      <TopNavDropdown />
    </TopNavStyles>
  )
}

export function TopNavAnonymous(): JSX.Element {
  return (
    <TopNavStyles>
      <LeftNavStyles>
        <Logo href={'/'} />
      </LeftNavStyles>
    </TopNavStyles>
  )
}
