import { useRouter } from 'next/router'
import { useContext } from 'react'
import styled from 'styled-components'
import { ActiveTeam, Menu, SuperuserIcon, TeamAdminIcon, UserContext } from '.'

const TabNavStyles = styled(Menu)`
  grid-area: tabnav;
  overflow-x: auto;
  overflow-y: hidden;

  display: flex;
  flex-direction: row;
  align-items: end;
  width: 100%;

  gap: 4px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 4px;

  background-color: ${({ theme }) => theme.colors.navbarBackground};

  border-bottom-color: ${({ theme }) => theme.colors.border};
  border-bottom-width: 1px;
  border-bottom-style: solid;
`

interface TabProps {
  readonly $hasAnnotation?: boolean
  readonly $active?: boolean
  readonly fullWidth?: boolean
}

const Tab = styled.button<TabProps>`
  display: flex;
  flex-flow: column;
  align-items: center;
  align-content: center;

  color: ${({ theme, $active }) =>
    $active ? theme.colors.foreground : theme.colors.font.primary};
  background: ${({ theme }) => theme.colors.background};
  padding: 8px;
  padding-top: ${({ $hasAnnotation }) => ($hasAnnotation ? ' 0' : 'inherit')};
  margin-bottom: -5px;
  white-space: nowrap;

  color: ${({ theme, $active }) =>
    $active ? theme.colors.foreground : theme.colors.font.primary};

  border: none;
  border-bottom-radius: 0;
  border-bottom-color: ${({ $active, theme }) =>
    $active ? theme.colors.foreground : 'transparent'};
  border-bottom-width: 4px;
  border-bottom-style: solid;

  cursor: pointer;

  &:hover {
    border-radius: var(--border-radius);
    border-bottom-left-radius: ${({ $active }) =>
      $active ? '0' : 'var(--border-radius)'};
    border-bottom-right-radius: ${({ $active }) =>
      $active ? '0' : 'var(--border-radius)'};
    color: ${({ theme }) => theme.colors.foreground};
    background-color: ${({ theme }) => theme.colors.menu.hoverBackground};
  }
`

const TabAnnotation = styled.div`
  font-size: 0.75rem;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  display: flex;
  gap: 4px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.menu.hoverBackground};
`

type MenuItem = {
  path: string
  activePath: string
  label: string
  isForSuperuserOnly?: boolean
  isForTeamAdminOnly?: boolean
  isEnabledForTeam?: (activeTeam: ActiveTeam) => boolean
}

const userMenuItems: MenuItem[] = [
  {
    path: '/user/dashboard',
    activePath: '/user/dashboard',
    label: 'Dashboard',
  },
  {
    path: '/user/settings',
    activePath: '/user/settings',
    label: 'Settings',
  },
  {
    path: '/user/auditlogs',
    activePath: '/user/auditlogs',
    label: 'Audit Logs',
  },
  {
    path: '/admin/containers?status=RUNNING',
    activePath: '/admin/containers',
    label: 'Containers',
    isForSuperuserOnly: true,
  },
  {
    path: '/admin/instances?status=RUNNING',
    activePath: '/admin/instances',
    label: 'Virtual Machines',
    isForSuperuserOnly: true,
  },
  {
    path: '/admin/storage?status=RUNNING',
    activePath: '/admin/storage',
    label: 'Storage',
    isForSuperuserOnly: true,
  },
  {
    path: '/admin/reservations?status=RESERVED',
    activePath: '/admin/reservations',
    label: 'Reservations',
    isForSuperuserOnly: true,
  },
  {
    path: '/admin/hardware',
    activePath: '/admin/hardware',
    label: 'Hardware',
    isForSuperuserOnly: true,
  },
  {
    path: '/admin/clusters',
    activePath: '/admin/clusters',
    label: 'Clusters',
    isForSuperuserOnly: true,
  },
  {
    path: '/admin/auditlogs',
    activePath: '/admin/auditlogs',
    label: 'Audit Logs',
    isForSuperuserOnly: true,
  },
]

const teamMenuItems: MenuItem[] = [
  {
    path: '/workloads?status=RUNNING',
    activePath: '/teams/[teamSlug]/workloads',
    label: 'Containers',
    isEnabledForTeam: (activeTeam) => activeTeam.isKubernetesEnabled,
  },
  {
    path: '/instances?status=RUNNING',
    activePath: '/teams/[teamSlug]/instances',
    label: 'Virtual Machines',
    isEnabledForTeam: (activeTeam) => activeTeam.isVmEnabled,
  },
  {
    path: '/storage?status=RUNNING',
    activePath: '/teams/[teamSlug]/storage',
    label: 'Storage',
    isEnabledForTeam: (activeTeam) => activeTeam.isStorageEnabled,
  },
  {
    path: '/hardware',
    activePath: '/teams/[teamSlug]/hardware',
    label: 'Hardware',
    isEnabledForTeam: (activeTeam) => activeTeam.isHardwareEnabled,
  },
  {
    path: '/settings',
    activePath: '/teams/[teamSlug]/settings',
    label: 'Settings',
    isForTeamAdminOnly: true,
  },
  {
    path: '/auditlogs',
    activePath: '/teams/[teamSlug]/auditlogs',
    label: 'Audit Logs',
    isForTeamAdminOnly: true,
  },
]

export function TabNav(): JSX.Element {
  const router = useRouter()
  const { activeTeam, currentUser, userIsActiveTeamAdmin } =
    useContext(UserContext)

  const { teamSlug } = router.query
  const isActiveTeamPath =
    router.pathname.includes('/teams/[teamSlug]') && activeTeam

  const menuItems = isActiveTeamPath ? teamMenuItems : userMenuItems
  const baseRoute = activeTeam ? `/teams/${teamSlug}` : ''

  return (
    <TabNavStyles>
      {menuItems
        .filter(
          (item) =>
            (!item.isForSuperuserOnly || currentUser.isSuperuser) &&
            (!item.isForTeamAdminOnly || userIsActiveTeamAdmin) &&
            (!item.isEnabledForTeam ||
              (activeTeam && item.isEnabledForTeam(activeTeam)))
        )
        .map((item) => (
          <Tab
            key={item.path}
            onClick={() => {
              router.push(baseRoute + item.path)
            }}
            $active={router.pathname.includes(item.activePath)}
            $hasAnnotation={item.isForSuperuserOnly || item.isForTeamAdminOnly}
          >
            {item.isForSuperuserOnly && (
              <TabAnnotation>
                <SuperuserIcon style={{ height: '0.75rem' }} /> Cloud admin
              </TabAnnotation>
            )}
            {item.isForTeamAdminOnly && (
              <TabAnnotation>
                <TeamAdminIcon style={{ height: '0.75rem' }} /> Team admin
              </TabAnnotation>
            )}
            {item.label}
          </Tab>
        ))}
    </TabNavStyles>
  )
}
