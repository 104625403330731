import styled from 'styled-components'

export const Details = styled.details`
  align-items: center;
  cursor: default;
  display: flex;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.foreground};
  border-color: ${({ theme }) => theme.colors.border};
  border-radius: var(--border-radius);
  border-style: solid;
  border-width: 1px;

  &:focus {
    border-color: ${({ theme }) => theme.colors.foreground};
  }
`
