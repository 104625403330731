import { SuperuserIcon, UserCircleIcon } from './Icons'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useLogoutMutation } from 'sdk'
import styled from 'styled-components'
import { useOuterClick, useUrls } from 'utils'
import {
  Divider,
  Dropdown,
  DropdownButton,
  Menu,
  MenuButton,
  MenuText,
  UserContext,
} from '.'
import { useContext } from 'react'

const TopNavDropdownContainer = styled.div`
  z-index: 3;
`

export function TopNavDropdown(): JSX.Element {
  const [open, setOpen] = useState(false)
  const innerRef = useOuterClick(() => {
    setOpen(false)
  })

  const { currentUser } = useContext(UserContext)

  const queryClient = useQueryClient()
  const router = useRouter()
  const { AUTH_URL, CLOUD_URL, ADMIN_URL } = useUrls()

  const { mutate } = useLogoutMutation({
    onSuccess: () => {
      queryClient.setQueriesData(['whoami'], { data: {} })
      queryClient.invalidateQueries(['whoami'])
      router.push(`${AUTH_URL}/?redirect=${CLOUD_URL}`)
    },
  })

  return (
    <TopNavDropdownContainer ref={innerRef}>
      <DropdownButton onClick={() => setOpen(!open)}>
        <UserCircleIcon />
      </DropdownButton>
      {open && (
        <Dropdown direction={'left'}>
          <Menu>
            {currentUser.isSuperuser && (
              <>
                <MenuText>
                  <SuperuserIcon style={{ height: '0.75rem' }} />
                  {' Superuser'}
                </MenuText>
                <MenuButton target="_blank" href={ADMIN_URL}>
                  API Admin Panel
                </MenuButton>
                <Divider />
                <MenuButton
                  target="_blank"
                  href="https://grafana.tenstorrent.net"
                >
                  Grafana
                </MenuButton>
                <Divider />
                <MenuButton
                  target="_blank"
                  href="https://weka.tenstorrent.net:14000"
                >
                  Weka
                </MenuButton>
                <MenuButton
                  target="_blank"
                  href="https://openstack.tenstorrent.net"
                >
                  OpenStack
                </MenuButton>
                <Divider />
                <MenuButton
                  target="_blank"
                  href="https://traefik.service.tenstorrent.net"
                >
                  Traefik Service Cluster
                </MenuButton>
                <MenuButton
                  target="_blank"
                  href="https://traefik.workload.tenstorrent.net"
                >
                  Traefik Workload Cluster
                </MenuButton>
                <Divider />
              </>
            )}
            <MenuText>User</MenuText>
            <MenuButton href={'/user/settings'}>Settings</MenuButton>
            <MenuButton href={'/user/auditlogs'}>Audit Logs</MenuButton>
            <Divider />
            <MenuButton
              onClick={(e) => {
                e.preventDefault()
                setOpen(false)
                mutate({ input: { confirm: true } })
              }}
            >
              Log out
            </MenuButton>
          </Menu>
        </Dropdown>
      )}
    </TopNavDropdownContainer>
  )
}

// ☰
